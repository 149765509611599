<template>
    <el-dialog :visible="visible" :title="dialogTitle" @close="handleCancel" :close-on-click-modal="false" width="1000px">
        <el-table :data="tableData" v-loading="loading" border height="400">
            <el-table-column prop="WebsiteTypeText" label="所属平台" width="150" align="center">
                <template slot-scope="{ row }">
                    <template v-if="row.ID > 0">
                        {{ row.WebsiteTypeText }}
                    </template>
                    <template v-else>
                        <el-select v-model="row.WebsiteType" placeholder="请选择">
                            <el-option v-for="item in websites" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="Name" label="账号" align="center">
                <template slot-scope="{ row }">
                    <template v-if="row.ID > 0">
                        {{ row.Name }}
                    </template>
                    <template v-else>
                        <el-input v-model="row.Name" autocomplete="off" placeholder="请输入账号"></el-input>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="Pwd" label="密码" width="150" align="center">
                <template slot-scope="{ row }">
                    <template v-if="row.ID > 0">
                        ******
                    </template>
                    <template v-else>
                        <el-input v-model="row.Pwd" autocomplete="off" placeholder="请输入密码" show-password></el-input>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="ErrorTime" label="出错时间" width="170" align="center">
                <template slot-scope="{ row }">
                    {{ row.ErrorTime ? row.ErrorTime : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="ErrorMsg" label="出错原因" width="200" align="center">
                <template slot-scope="{ row }">
                    {{ row.ErrorMsg ? row.ErrorMsg : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="" header-align="center" align="center" label="操作" width="160">
                <template slot-scope="{ row,$index }">
                    <el-popconfirm v-if="row.ID > 0" confirm-button-text='确认' cancel-button-text='不用了' icon="el-icon-info"
                        icon-color="red" title="确定删除吗？" @confirm="handleDelete(row, $index)">
                        <el-button type="danger" slot="reference" :disabled="row.State == 1">删除</el-button>
                    </el-popconfirm>
                    <el-button type="primary" @click="handleAdd(row, $index)" v-if="row.ID <= 0">新增</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
export default {
    name: 'SynAccountSetting',
    data() {
        return {
            websites: [
                {
                    value: '17qcc',
                    label: '青创网'
                }
            ],
            tableData: [],
            visible: false,
            loading: false,
            dialogTitle: '账号设置',
        };
    },
    methods: {
        async open() {
            this.visible = true;
            await this.getData();
        },
        addEmptyRow() {
            var count = this.tableData.length;
            if (count <= 0 || (count > 0 && this.tableData[count - 1].ID > 0)) {
                this.tableData.push({
                    "ID": 0,
                    "CustomerID": "",
                    "Name": "",
                    "Pwd": "",
                    "WebsiteType": "",
                    "State": 0,
                    "ErrorTime": "",
                    "ErrorMsg": "",
                    "WebsiteTypeText": "-"
                });
            }
        },
        async getData() {
            var that = this;
            that.loading = true;
            that.tableData = [];
            let { code, msg, data } = await submit("/api/product/spider/getSynAccounts");
            if (code == 200) {
                that.tableData = data ?? [];
            } else {
                this.$baseMessage(msg, "error");
            }
            this.addEmptyRow();
            that.loading = false;
        },
        async handleAdd(row, rowIndex) {
            if (row.Name == '') {
                this.$message({ type: 'error', message: "账号不能为空" });
                return;
            }
            if (row.Pwd == '') {
                this.$message({ type: 'error', message: "密码不能为空" });
                return;
            }
            let { code, msg } = await submit("/api/product/spider/addSynAccount", row);
            if (code == 200) {
                this.$message({ type: 'success', message: msg });
                await this.getData();
            } else {
                this.$message({ type: 'error', message: msg });
            }
        },
        async handleDelete(row, rowIndex) {
            if (this.tableData.length > 1) {
                var lastRow = this.tableData[rowIndex];
                if (lastRow.ID <= 0) {
                    return;
                }
                let { code, msg } = await submit("/api/product/spider/deleteSynAccount", { ID: row.ID });
                if (code == 200) {
                    this.$message({ type: 'success', message: msg });
                    this.tableData.splice(rowIndex, 1);
                }
            }
        },
        handleCancel() {
            this.tableData = [];
            this.visible = false;
        },
    }
}
</script>
<style lang="scss" scoped>
.tooltip-item {
    margin: 4px;
}

::v-deep {

    .el-table__header th {
        background-color: #f3f7f9 !important;
        border-color: #ebeef4 !important;
    }
}
</style>
